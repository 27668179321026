/* styles.css or your custom stylesheet */

/* Navbar container */
.navbar-custom {
    background-color: #ffffff; /* Background color */
    height: 80px; /* Navbar height */
    border-bottom: 1px solid #dddddd; /* Bottom border */
  }
  
  /* Navbar Brand (logo and brand name) */
  .navbar-brand-custom {
    color: #00215B; /* Brand color */
    font-size: 24px; /* Brand font size */
    font-weight: bold; /* Brand font weight */
  }
  
  .navbar-brand-custom img {
    width: 80px; /* Logo width */
    height: 80px; /* Logo height */
    margin-right: 10px; /* Margin between logo and brand text */
  }
  
  /* Navbar Links (menu items) */
  .navbar-nav .nav-link {
    color: #00215B; /* Link color */
    font-size: 18px; /* Link font size */
    font-weight: bold; /* Link font weight */
    margin-right: 20px; /* Margin between links */
  }
  
  .navbar-nav .nav-link:hover {
    color: #F45905; /* Hover color */
  }
  
  /* Mobile responsive adjustments */
  @media (max-width: 768px) {
    .navbar-brand-custom {
      font-size: 20px;
    }
    
    .navbar-nav .nav-link {
      font-size: 16px;
      margin-right: 10px;
    }
  }
  