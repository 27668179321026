*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#galary{
    height:45vh;
    width: 100%;
}
#galary>.homegalary{
    height: 75%;
    width: 100%;
    background-image: url("/public/Assets/galary.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
#galary>.namegalary{
  height:25% ;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
#galary>.namegalary>h2{
  font-size: 35px;
  font-weight: bold;
  color: #034CA8;
}
#galary>.namegalary>button{
  height: 45px;
  width: 16%;
  padding: 0px;
  font-size: 20px;
  font-weight: bold;
  color: #0d6efd;
  background-color: #86b7fe;
  border: none;
  border-radius: 5px;
}

@media (max-width: 480px) {
  #galary > .homegalary > h1 {
      width: 80%; /* Adjusted width for better responsiveness */
      top: 90px; /* Adjusted position for better alignment */
  }
  #galary > .namegalary{
    display: flex;
    /* flex-direction: column; */
  }
  #galary > .namegalary > button {
      width: 30%; /* Adjusted width for better responsiveness */
      font-size: 16px; /* Adjusted font size for better readability */
  }
}