*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


/* homepage.module.css */

.container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    /* background-color: blue; */
  }
  
  .column {
    flex: 1;
    margin: 0 10px;
    /* background-color: #46e216; */
  }
 
  .column>h5{
    /* color: #164ce2; */
    font-weight: bold;
  }

  .buttonGroup {
    display: flex;
    flex-direction: column;
    width: 250px;
    /* background-color: #d810b7; */
  }
  
  .button {
    margin-bottom: 11px;
    padding: 15px;
    background-color: #034CA8;
    color: white;
    border: none;
  }
  .button:hover{
    background-color:#504F56;
  }
  
  .imageGrid {
    /* display: grid; */
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 30px;
    /* background-color: red; */
   
    
  }
  
  .image {
    width: 100%;
    height: auto;
    /* margin: 15px; */
    padding: 10px;
  }
  

#about{
    height: 45vh;
    width: 100%;
    background-color:#b1c4cf;
    display: flex;
    justify-content: center;
    background-position: center;
    background-image: url("https://www.atishgroup.com/wp-content/uploads/2021/05/banner-contact.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

#about>.heading{
    height: 100%;
    width: 66%;
    /* background-color: red; */
    display: flex;
    /* justify-content: baseline; */
    align-items: center;
    /* flex-direction: column; */
}
#about>.heading>h2{
    font-size: 50px;
    font-family: Arial, Helvetica, sans-serif;
    
    /* padding: 20px; */
    position: relative;
    left: 10px;
    
}
#about>.heading>h1{
    position: relative;
    left: 158px;
    top: -35px;
    /* font-size: 50px; */
    color: #034CA8;
    font-weight: bold;
}
#about>.heading>h3{
    position: relative;
    top: 50px;
    right: 265px;
}

#about>.call{
    height: 100%;
    width: 40%;
    /* background-color:blue; */
    display: flex;
    justify-content: end;
    align-items: end;
}

#about>.call>.contact{
    height: 140px;
    width: 335px;
    background-color:#FFFFFF;
    position: relative;
    top: 15px;
    right: 50px;
    border-top: 10px solid #034CA8;
    /* box-shadow: 10px 10px 0px 5px rgb(235, 240, 241); */
    display: flex;
    justify-content: center;
    align-items: center;
}
#about>.call>.contact>div{
    color: #16213E;
}
#about>.call>.contact>div>h3{
color: #08236d;
/* font-size: xx-large; */
font-weight: bolder;
font-family: Arial, Helvetica, sans-serif;
}



@media (max-width: 768px) {

    .container {
        flex-direction: column; /* Stack columns vertically on smaller screens */
    }

    .column {
        flex: 1 1 100%; /* Full width columns */
        margin: 10px 0;
    }

    .button {
        margin-bottom: 10px;
        font-size: 0.9em; /* Adjust font size for buttons */
    }

    .imageGrid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 15px; /* Adjust gap for smaller screens */
    }

    #about .heading h1 {
        font-size: 2em;
        left: 14px;
    }

    #about .heading h2,
    #about .heading h3 {
        font-size: 1.2em;
    }

    #about .call .contact {
      position:relative;
        /* top: 14px;
        left: 29px; */
    }
}